/* custom scroll navbar */
.fixedHeader{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: white;
    height: 72px;
    box-shadow: 0px 4px 16px rgba(43, 52, 69, 0.1);
    -webkit-animation: animate 250ms ease-in-out;
    animation: animate 250ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes animate {
    0% {
        top: -72px
    
    }
    100% {
        top: 0
    }
}

.nav_link {
    position: relative;
    text-decoration: none; /* Ensure no default underline */
    color: inherit;

}
  
  .nav_link::after {
    content: '';
    position: absolute;
    background-color: #383838;
    height: 2px;
    width: 100%;
    left: 0;
    bottom: -2px; /* Adjusted to position the line under the text */
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.2s ease-in-out;
  }
  
  .nav_link:hover::after,
  .nav_link.active::after {
    transform: scaleX(1);
    transform-origin: left;
  }
  

