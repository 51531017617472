*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  a:hover, a:focus, a:active{
    color: inherit;
  }
  
  a .active {
    font-weight: 700;
  }
  
  li {
    list-style: none;
  }